import { css, SerializedStyles } from '@emotion/core';

import { buttons } from './button.styles';
import { color } from './mixins.styles';
import { spacers } from './spacers.styles';
import { typescale } from './typescale.styles';
import { colors, fontFamily, globalRadial, unit } from './vars.styles';

/**
 * Input base
 */
const input = css`
  padding: 0;
  margin: 0;
  border-radius: ${globalRadial + 1}px;

  display: inline-flex;
  width: 100%;

  cursor: pointer;
  white-space: nowrap;

  text-decoration: none;
  font-family: ${fontFamily.sans};

  background: ${colors.bg};
  border: 1px solid ${color(colors.dark, 0.2)};
  color: ${colors.dark};
  font-size: 16px;
  line-height: 24px;

  transition: border-color 0.2s ease-out;

  &::placeholder {
    color: ${color(colors.dark, 0.4)};
  }

  &:hover {
    border-color: ${color(colors.dark, 0.6)};
    cursor: pointer;
  }

  &:focus {
    border-color: ${color(colors.dark, 0.6)};
    outline: none;
  }

  &[disabled] {
    background: gray;
    cursor: not-allowed;
  }
`;

const inputError = css`
  ${input}

  border: 1px solid rgba(241, 52, 86, 0.6);
`;

const inputNumber = css`
  ${input}

  padding-right: 0;
`;

const inputRepeatPw = css`
  ${input}

  padding-left: 48px !important;
`;

const inputSelect = css`
  ${input}

  height: ${unit * 7}px;
  line-height: ${unit * 6}px;
`;

const inputSelectMultiple = css`
  ${inputSelect}

  min-height: 160px;
`;

const inputTextarea = css`
  ${input}

  width: 100%;
  height: 160px;
  max-height: 600px;
  resize: vertical;
  padding: ${unit * 4}px;
  white-space: pre-wrap;
  overflow-wrap: normal;
`;

const inputTextareaError = css`
  ${inputTextarea}

  border-color: ${colors.warning};
`;

const inputCheckbox = css`
  display: block;
  position: relative;
  padding-left: 38px;
  margin-bottom: 6px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 4px;
    transition: background-color 0.15s ease-out;
  }

  &:hover input ~ span {
    background-color: ${color(colors.dark, 0.2)};
  }

  & input:checked ~ span {
    background-color: ${colors.primary};
  }

  span:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ span:after {
    display: block;
  }

  & span:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const inputRadio = css`
  ${inputCheckbox}

  &+label {
    &:before {
      border-radius: 50%;
    }
  }
`;

const switchLabel = css`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  & > span {
    ${spacers.left.medium}
  }
`;

const inputSelectError = css`
  & > div {
    border-color: ${colors.warning};
  }
`;

const passwordEye = css`
  position: absolute;
  right: 16px;
  top: 20px;
  cursor: pointer;
`;

const passwordCheck = css`
  position: absolute;
  left: 16px;
  top: 36px;
`;

const tooltipIcon = css`
  position: absolute;
  right: 0;
  top: -4px;
`;

const tooltipBox = css`
  ${typescale.tiny}

  color: ${color(colors.text)};
  position: absolute;
  right: 0;
  bottom: 80px;
  transform: translateX(50%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  width: fit-content;
  max-width: 282px;
  padding: ${unit * 4}px;
  background: white;
`;

const passwordWrapper = css`
  position: relative;
`;

const forgotPassword = css`
  ${typescale.tiny}

  display: block;
  text-align: right;
  width: 100%;
  margin-top: ${unit}px;
`;

const progressBar = css`
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 2px;
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
  background: transparent;
  transition: background-color 0.15s ease-out;

  & > span {
    width: 19%;
  }
`;

const progressBarStepOn = css`
  background-color: ${colors.success};
  transition: background-color 0.15s ease-out;
`;
const progressBarStepOff = css`
  background-color: transparent;
  transition: background-color 0.15s ease-out;
`;

const closeBtn = css`
  ${buttons.container}
  position: absolute;
  top: 50%;
  right: 26px;
  transform: translateY(-50%);
`;

/**
 * Input sizes
 */
const xsmall = css`
  height: ${unit * 8}px;
  line-height: ${unit * 5}px;
  padding: ${unit * 1 + 2}px ${unit * 3}px;
`;

const small = css`
  height: ${unit * 10}px;
  line-height: ${unit * 5}px;
  padding: ${unit * 2 + 2}px ${unit * 4}px;
`;

const normal = css`
  height: ${unit * 12}px;
  line-height: ${unit * 5}px;
  padding: ${unit * 3 + 2}px ${unit * 4}px;
`;

/**
 * Size helper
 */
const createSizeMap = (style: SerializedStyles) => ({
  xsmall: css(style, xsmall),
  small: css(style, small),
  normal: css(style, normal),
});

/**
 * Export all available input styles
 */
export const inputs = {
  input: createSizeMap(input),
  inputNumber: createSizeMap(inputNumber),
  inputSelect: createSizeMap(inputSelect),
  inputSelectMultiple: createSizeMap(inputSelectMultiple),
  inputRepeatPw: createSizeMap(inputRepeatPw),
  inputSelectError,
  inputTextarea,
  inputTextareaError,
  inputCheckbox: inputCheckbox,
  inputRadio: createSizeMap(inputRadio),
  inputError: createSizeMap(inputError),
  switchLabel,
  passwordEye,
  passwordCheck,
  tooltipIcon,
  tooltipBox,
  passwordWrapper,
  forgotPassword,
  progressBar,
  progressBarStepOn,
  progressBarStepOff,
  closeBtn,
};
